import http from '../api/http-common';

class downloadService {
	download(param) {
		http.defaults.responseType = 'blob';
		return http.get('/download/send/' + param);
	}

	// downloadcode() {
	// 	http.defaults.responseType = 'blob';
	// 	return http.get('/downloadcode/send');
	// }
}
export default new downloadService();
